import Page from '../Page/Page';

interface ComponentProps {
    className: string;
}

function NewProject({ className }: ComponentProps) {
    return (
        <Page className={className}>
            <p>THIS IS A PLACEHOLDER TEST NEW PROJECT</p>
        </Page>
    );
}

export default NewProject;
